@import "../../assets/styles/_variables";

.social-media-icons {
	
	display: flex;
	justify-content: center;
	
	&.vertical {
		
		flex-direction: column;
		
	}
	
	&.horizontal {
		
		flex-direction: row;
		
	}
	
	.social-media-icon {
		
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px;
		color: white !important;
		
		transition: transform 0.25s ease-in-out;
		will-change: transform;
		
		@include scale-fix;
		
		&:hover {
			
			transform: scale(1.2);
			
		}
		
		& > * {
			
			height: 100%;
			width: auto;
			
		}
		
	}
	
}
