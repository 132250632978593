@import url(https://fonts.googleapis.com/css2?family=PT+Sans&display=swap);
@font-face{font-family:"Spy Agency";src:url(/static/media/spyagency3_2.0b8c759b.ttf) format("truetype")}*{margin:0;padding:0;box-sizing:border-box}html,body,#react-root{height:100%;width:100%;font-family:"PT Sans", sans-serif;font-size:16px}#react-root{display:flex;flex-direction:column}#react-root .page{flex:1 1}

.page-home main img.banner-image{width:100%;padding:10px 0}.page-home main p{text-align:justify}

:root{--page-width: 1000px;--color-bg: #282C34;--color-fg: #fff;--color-first-blue: #0066B3;--color-first-red: #ED1C24}.page{height:100%;padding:0 20px;overflow:auto;background-color:#282C34;background-color:var(--color-bg);color:#fff;color:var(--color-fg)}.page.centered{display:flex;flex-direction:column;align-items:center}.page main{flex:1 1;width:100%;max-width:1000px;max-width:var(--page-width);padding:10px 10px}.page.full-width main{max-width:none}.page ::selection{background:#fff;background:var(--color-fg);color:#282C34;color:var(--color-bg)}.page a{color:#AFAFFA}.page a:visited{color:#7A4AFF}

:root{--page-width: 1000px;--color-bg: #282C34;--color-fg: #fff;--color-first-blue: #0066B3;--color-first-red: #ED1C24}header{width:100%;max-width:1000px;max-width:var(--page-width);margin-top:10px;background-color:#282C34;background-color:var(--color-bg)}header div.header-content{display:flex;padding:0 10px}header div.header-content .logo{display:inline-block;margin-right:10px}header div.header-content nav{flex:1 1;display:flex;justify-content:space-evenly;align-items:center;padding:0 5%}header div.header-content nav .nav-item{flex:1 1;position:relative;display:flex;justify-content:center}header div.header-content nav .nav-item>a{color:inherit;padding:5px;text-align:center;text-decoration:none;text-transform:uppercase;letter-spacing:-0.5px;white-space:nowrap}header div.header-content nav .nav-item>a:hover:before,header div.header-content nav .nav-item>a:hover:after{opacity:1}header div.header-content nav .nav-item>a:before,header div.header-content nav .nav-item>a:after{content:" — ";position:absolute;white-space:pre;opacity:0;transition:opacity 0.25s ease}header div.header-content nav .nav-item>a:before{transform:translateX(-100%)}header div.header-content nav .nav-item:hover .nav-item-dropdown{display:inline;display:initial}header div.header-content nav .nav-item .nav-item-dropdown{position:absolute;top:100%;display:none;flex-direction:column;background-color:#282C34;background-color:var(--color-bg);box-shadow:0 8px 8px #000A;padding:6px;border-left:1px solid grey;border-bottom:1px solid grey;border-right:1px solid grey}header div.header-content nav .nav-item .nav-item-dropdown .nav-item-dropdown-entry{display:block;color:#fff;color:var(--color-fg);text-decoration:none}header div.header-content nav .nav-item .nav-item-dropdown .nav-item-dropdown-entry:hover{text-decoration:underline}header hr{margin-top:10px}

.logo{font-family:"Spy Agency", sans-serif;text-shadow:2px 2px #0008}

:root{--page-width: 1000px;--color-bg: #282C34;--color-fg: #fff;--color-first-blue: #0066B3;--color-first-red: #ED1C24}footer{width:100%;max-width:1000px;max-width:var(--page-width);margin-bottom:10px;background-color:#282C34;background-color:var(--color-bg)}footer .frc-logo-horizontal-rule{margin-bottom:8px}footer .footer-content{padding:0 5px}footer .footer-content .row{display:flex;justify-content:space-between;align-items:center}footer .footer-content .row p.repo-link,footer .footer-content .row p.made-with-love{flex:1 1;display:inline-block;font-size:0.7em}footer .footer-content .row p.repo-link a,footer .footer-content .row p.made-with-love a{color:#8CA9EE}footer .footer-content .row p.made-with-love{text-align:right}footer .footer-content .row .social-media-icons{flex:1 1}

.frc-logo-horizontal-rule{height:1.5rem;display:flex;justify-content:center;align-items:center}.frc-logo-horizontal-rule hr{flex:1 1}.frc-logo-horizontal-rule img{height:100%;margin:0 0.5rem;background:var(--color-bg)}

:root{--page-width: 1000px;--color-bg: #282C34;--color-fg: #fff;--color-first-blue: #0066B3;--color-first-red: #ED1C24}.social-media-icons{display:flex;justify-content:center}.social-media-icons.vertical{flex-direction:column}.social-media-icons.horizontal{flex-direction:row}.social-media-icons .social-media-icon{display:flex;justify-content:center;align-items:center;padding:5px;color:white !important;transition:transform 0.25s ease-in-out;will-change:transform;-webkit-backface-visibility:hidden;backface-visibility:hidden;transform:perspective(1px) translateZ(0);-webkit-font-smoothing:subpixel-antialiased}.social-media-icons .social-media-icon:hover{transform:scale(1.2)}.social-media-icons .social-media-icon>*{height:100%;width:auto}

.page-meet-the-team main .text-image-box:first-of-type{margin-top:10px}

h1.page-heading{font-family:"Spy Agency", sans-serif;font-size:4em;text-shadow:4px 3px grey;text-align:center;margin:20px 0}

:root{--page-width: 1000px;--color-bg: #282C34;--color-fg: #fff;--color-first-blue: #0066B3;--color-first-red: #ED1C24}.text-image-box{display:flex;flex-direction:row;margin:25px 0;background-color:#FFF1;border-radius:5px;overflow:hidden}.text-image-box.reverse .img-box{order:1}.text-image-box.reverse .text-box{order:2}@media (max-width: 700px){.text-image-box{flex-direction:column}.text-image-box .img-box{order:1}.text-image-box .text-box{order:2}}.text-image-box .image-box{flex:3 1;display:flex;flex-direction:column;justify-content:center;align-items:center;max-height:50vh;aspect-ratio:4/3;overflow:hidden}.text-image-box .image-box img{height:100%;width:100%;object-fit:cover}.text-image-box .text-box{flex:2 1;display:flex;flex-direction:column;padding:20px;justify-content:center}.text-image-box .text-box p{text-align:center;line-height:1.6}

.page-not-found main{display:flex;flex-direction:column;justify-content:center;align-items:center}.page-not-found main h1.page-heading{text-align:center}.page-not-found main .sockets{margin:30px 0}.page-not-found main .sockets p.aside{font-size:0.8em;font-style:italic}.page-not-found main .sockets h1.cant-find{text-align:center;font-size:2em}.page-not-found main a{color:white}

.page-contact-us main{display:flex;flex-direction:column;align-items:center}.page-contact-us main p{text-align:center;margin-bottom:20px}.page-contact-us main ul{list-style-type:"➤";line-height:1.5}.page-contact-us main ul li{padding-left:6px}

.page-meet-the-leads main .text-image-box .text-box .lead-header{display:flex;align-items:baseline;justify-content:center;flex-wrap:wrap;margin-bottom:5px}.page-meet-the-leads main .text-image-box .text-box .lead-header h4,.page-meet-the-leads main .text-image-box .text-box .lead-header h6{padding:0 5px;white-space:nowrap}.page-meet-the-leads main .text-image-box .text-box .lead-header h4{font-size:1.6em}.page-meet-the-leads main .text-image-box .text-box .lead-header h6{font-size:1em;opacity:0.8;font-weight:normal}.page-meet-the-leads main .text-image-box.ian-elder .image-box img{object-position:center 10%}

.page-meet-the-mentors main .text-image-box .text-box .mentor-header{display:flex;align-items:baseline;justify-content:center;flex-wrap:wrap;margin-bottom:5px}.page-meet-the-mentors main .text-image-box .text-box .mentor-header h4,.page-meet-the-mentors main .text-image-box .text-box .mentor-header h6{padding:0 5px;white-space:nowrap}.page-meet-the-mentors main .text-image-box .text-box .mentor-header h4{font-size:1.6em}.page-meet-the-mentors main .text-image-box .text-box .mentor-header h6{font-size:1em;opacity:0.8;font-weight:normal}.page-meet-the-mentors main .text-image-box.jennifer-dezeeuw .image-box img{object-position:center 35%}.page-meet-the-mentors main .text-image-box.leilani-sigsbey .image-box img{width:140%;margin-left:22%;object-position:center 25%}.page-meet-the-mentors main .text-image-box.trevor-sears .image-box img{object-position:center 20%}.page-meet-the-mentors main .text-image-box.jeremiah-stieve .image-box img{object-position:center 10%}.page-meet-the-mentors main .text-image-box.russ-riker .image-box img{object-position:center 10%}.page-meet-the-mentors main .text-image-box.brian-lettinga .image-box img{width:140%;object-position:center 45%}.page-meet-the-mentors main .text-image-box.eric-smith .image-box img{object-position:center 10%}.page-meet-the-mentors main .text-image-box.paul-dezeeuw .image-box img{object-position:center 35%}

.page-sponsor-us main{display:flex;flex-direction:column;align-items:center}.page-sponsor-us main img{max-width:80%;border:5px solid transparent;outline:2px solid white;margin-bottom:30px}.page-sponsor-us main p{text-align:center;font-size:1.1rem;margin-bottom:15px}.page-sponsor-us main p span{text-decoration:underline}.page-sponsor-us main p span:before,.page-sponsor-us main p span:after{content:"\""}.page-sponsor-us main a{text-decoration:none;background:var(--color-first-blue);font-weight:bold;font-size:1.3rem;padding:12px 50px;color:white;border-radius:6px;border:2px solid white}.page-sponsor-us main a:visited{color:white}

