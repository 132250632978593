.page-not-found main {
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	h1.page-heading {
		
		text-align: center;
		
	}
	
	.sockets {
		
		margin: 30px 0;
		
		p.aside {
			
			font-size: 0.8em;
			font-style: italic;
			
		}
		
		h1.cant-find {
			
			text-align: center;
			font-size: 2em;
			
		}
		
	}
	
	a {
		
		color: white;
		
	}
	
}
