@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

@font-face {
	
	font-family: "Spy Agency";
	src: url("/assets/fonts/spyagency3_2.ttf") format("truetype");
	
}

* {
	
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	
}

html, body, #react-root {
	
	height: 100%;
	width: 100%;
	
	font-family: "PT Sans", sans-serif;
	font-size: 16px;
	
}

#react-root {
	
	display: flex;
	flex-direction: column;
	
	.page {
		
		flex: 1;
		
	}
	
}
