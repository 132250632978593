$pageWidth: 1000px;

$backgroundColor: #282C34;
$foregroundColor: white;
$firstBlue: #0066B3;
$firstRed: #ED1C24;

:root {
	
	--page-width: #{$pageWidth};
	--color-bg: #{$backgroundColor};
	--color-fg: #{$foregroundColor};
	--color-first-blue: #{$firstBlue};
	--color-first-red: #{$firstRed};
	
}

@mixin expandToMaxWidth($maxWidth: $pageWidth) {
	
	width: 100%;
	
	@if ($maxWidth == $pageWidth) {
		
		max-width: var(--page-width);
		
	} @else {
		
		max-width: $maxWidth;
		
	}
	
}

@mixin scale-fix {
	
	backface-visibility: hidden;
	transform: perspective(1px) translateZ(0);
	-webkit-font-smoothing: subpixel-antialiased;
	
}
