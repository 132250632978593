@import "../../../assets/styles/variables";

header {
	
	$spacing: 10px;
	
	@include expandToMaxWidth();
	
	margin-top: $spacing;
	background-color: var(--color-bg);
	
	div.header-content {
		
		display: flex;
		padding: 0 $spacing;
		
		.logo {
			
			display: inline-block;
			margin-right: $spacing;
			
		}
		
		nav {
			
			flex: 1;
			
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			padding: 0 5%;
			
			.nav-item {
				
				flex: 1;
				position: relative;
				display: flex;
				justify-content: center;
				
				& > a {
					
					color: inherit;
					padding: 5px;
					text-align: center;
					text-decoration: none;
					text-transform: uppercase;
					letter-spacing: -0.5px;
					white-space: nowrap;
					
					&:hover:before, &:hover:after{
						
						opacity: 1;
						
					}
					
					&:before, &:after {
						
						content: " — ";
						position: absolute;
						white-space: pre;
						opacity: 0;
						transition: opacity 0.25s ease;
						
					}
					
					&:before {
						
						transform: translateX(-100%);
						
					}
					
				}
				
				&:hover {
					
					.nav-item-dropdown {
					
						display: initial;
					
					}
					
				}
				
				.nav-item-dropdown {
					
					position: absolute;
					top: 100%;
					display: none;
					flex-direction: column;
					background-color: var(--color-bg);
					box-shadow: 0 8px 8px #000A;
					padding: 6px;
					border: {
						
						left: 1px solid grey;
						bottom: 1px solid grey;
						right: 1px solid grey;
						
					}
					
					.nav-item-dropdown-entry {
					
						display: block;
						color: var(--color-fg);
						text-decoration: none;
						
						&:hover {
							
							text-decoration: underline;
							
						}
					
					}
					
				}
				
			}
			
		}
		
	}
	
	hr {
		
		margin-top: $spacing;
		
	}
	
}
