.page-meet-the-mentors main {
	
	.text-image-box {
		
		.text-box {
			
			.mentor-header {
				
				display: flex;
				align-items: baseline;
				justify-content: center;
				flex-wrap: wrap;
				margin-bottom: 5px;
				
				h4, h6 {
					
					padding: 0 5px;
					white-space: nowrap;
					
				}
				
				h4 {
					
					font-size: 1.6em;
					
				}
				
				h6 {
					
					font-size: 1em;
					opacity: 0.8;
					font-weight: normal;
					
				}
				
			}
			
		}
		
		&.jennifer-dezeeuw .image-box img {
			
			object-position: center 35%;
			
		}
		
		&.leilani-sigsbey .image-box img {
			
			width: 140%;
			margin-left: 22%;
			object-position: center 25%;
			
		}
		
		&.trevor-sears .image-box img {
			
			object-position: center 20%;
			
		}
		
		&.jeremiah-stieve .image-box img {
			
			object-position: center 10%;
			
		}
		
		&.russ-riker .image-box img {
			
			object-position: center 10%;
			
		}
		
		&.brian-lettinga .image-box img {
			
			width: 140%;
			object-position: center 45%;
			
		}
		
		&.eric-smith .image-box img {
			
			object-position: center 10%;
			
		}
		
		&.paul-dezeeuw .image-box img {
			
			object-position: center 35%;
			
		}
		
	}

}
