.page-contact-us main {
	
	display: flex;
	flex-direction: column;
	align-items: center;
	
	p {
		
		text-align: center;
		margin-bottom: 20px;
		
	}
	
	ul {
	
		list-style-type: "➤";
		line-height: 1.5;
		
		li {
			
			padding-left: 6px;
			
		}
	
	}
	
}
