.frc-logo-horizontal-rule {
	
	height: 1.5rem;
	
	display: flex;
	justify-content: center;
	align-items: center;
	
	hr {
		
		flex: 1;
		
	}
	
	img {
		
		height: 100%;
		margin: 0 0.5rem;
		background: var(--color-bg)
		
	}
	
}
