.page-home main {
	
	img.banner-image {
		
		width: 100%;
		padding: 10px 0;
		
	}
	
	p {
		
		text-align: justify;
		
	}
	
}
