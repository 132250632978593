@import "../../assets/styles/_variables";

.text-image-box {
	
	display: flex;
	flex-direction: row;
	margin: 25px 0;
	background-color: #FFF1;
	border-radius: 5px;
	overflow: hidden;
	
	&.reverse {
		
		.img-box {
			
			order: 1;
			
		}
		
		.text-box {
			
			order: 2;
			
		}
		
	}
	
	@media (max-width: 700px) {
		
		flex-direction: column;
		
		.img-box {
			
			order: 1;
			
		}
		
		.text-box {
			
			order: 2;
			
		}
		
	}
	
	.image-box {
		
		flex: 3 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-height: 50vh;
		aspect-ratio: 4/3;
		overflow: hidden;
		
		img {
			
			//min-height: 100%;
			//max-width: 100%;
			height: 100%;
			width: 100%;
			object-fit: cover;
			
		}
		
	}
	
	.text-box {
		
		flex: 2 1;
		display: flex;
		flex-direction: column;
		padding: 20px;
		justify-content: center;
		
		p {
			
			text-align: center;
			line-height: 1.6;
			
		}
		
	}
	
}
